<template>
    
    <b-row>
        
        <b-col md='9' cols='12'>
            <b-card v-if='details' no-body  class=' overflow-hidden'>
                <div class="attachment d-flex align-items-end "
                    :style="{
                        margin: '0px',
                        width: '100%',
                        height: '275px',
                        padding: '8px',
                       
                        '--thumb': `url(${details.media.urls.thumb})`,
                        '--placeholder': `url(${details.media.urls.placeholder})`,
                    }"
                >
                     <div class='d-flex justify-content-between text-muted w-100' style='font-size: 11px;'>
                        <b-badge class="d-flex align-items-center" variant="dark">
                            <feather-icon icon="EyeIcon" size="10" style='font-weight: 400;' />
                            <span style="padding-left: 5px; font-size: 10px; font-weight: 400;">{{ details.views }}</span>
                        </b-badge>
                        <b-badge class="d-flex align-items-center" variant="dark" style='font-weight: 400; font-size: 10px;'>
                             {{ utils.lastActivityFormat(details.timestamp) }}
                        </b-badge>
                    </div>
                </div>
                <div style="padding: 0 32px 16px 32px;">
                    <div class="post-content-view ql-editor" style='min-height: 255px; margin-bottom: 16px;' v-html="$i18n.locale == 'ru' ? details.content : details.content_en || details.content"></div>
                    <div class='ce-block'>
                        <div class='d-flex justify-content-between'>
                            <div class='d-flex'>
                                <b-badge v-for="(category, index) in details.categories" :key="index" class='mr-1'>
                                    {{ $i18n.locale == 'ru' ? category.title : category.title_en || category.title}}
                                </b-badge>
                            </div>
                        </div>
                       
                    </div>
                </div>
                
            </b-card>
        </b-col>
        <b-col md='3' cols='12'>
            
            
            <b-card no-body class="shadow" >
                <b-card-header>
                    <b-card-title>
                        {{$t('news.similar')}}
                    </b-card-title>
                </b-card-header>
                <b-card-body class="pl-1 pr-1">
                    <b-media
                        v-for="( post, index) in similar"
                        :key="index"
                        no-body
                        :class="index ? 'mt-2 cursor-pointer' : 'cursor-pointer'"
                        @click="$router.push({ name: 'user-news-view', params:{ slug: post.slug }})"
                      >
                        <b-media-body>
                          <h6 class="blog-recent-post-title" >
                            <b-link class="text-body-heading" style='font-size: 14px; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;'>
                              {{ $i18n.locale == 'ru' ? post.header : post.header_en || post.header }}
                            </b-link>
                          </h6>
                          <span class="text-muted mb-0">
                            {{ utils.lastActivityFormat(post.timestamp) }}
                          </span>
                        </b-media-body>
                    </b-media>
                </b-card-body>
            </b-card>
            
            <b-card no-body class="shadow" style="position: sticky; top: 22px;">
                <b-card-header class="pb-1">
                    <b-card-title>
                        {{$t('news.headings')}}
                    </b-card-title>
                </b-card-header>
                <b-list-group flush>
                    <b-list-group-item 
                        v-for="(category, index) in News.categories" 
                        :key="index"
                        class="cursor-pointer d-flex justify-content-between" 
                        v-ripple
                    > 
                        <span class="text-body-heading">{{ $i18n.locale == 'ru' ? category.title : category.title_en || category.title }}</span>
                        <b-badge>{{ category.news_count }}</b-badge>
                    </b-list-group-item>
                </b-list-group>
            </b-card>
            
            
        </b-col>
        
    </b-row>

</template>

<script>
    
    import EditorJS from '@editorjs/editorjs';

    import Header from '@editorjs/header'; 
    import List from '@editorjs/list'; 

    import Attaches from "@editorjs/attaches"
    import Personality from "@editorjs/personality"
    import CheckList from "@editorjs/checklist"
    import Delimiter from "@editorjs/delimiter"
    import Quote from "@editorjs/quote"
    import Link from "@editorjs/link"
    import Image from "@editorjs/image"

    import { kFormatter } from '@core/utils/filter'

    import NewsView from "@/components/NewsView"

    import News from "@/modules/contents/news"
    import utils from "@/modules/utils/"

    export default {

        data() {
            return {
                kFormatter,
                utils,
                News,
                details: null,
                similar: [],
                search_query: '',
                commentCheckmark: '',
                blogDetail: [],
                blogSidebar: {},
                socialShareIcons: [
                  'GithubIcon',
                  'GitlabIcon',
                  'FacebookIcon',
                  'TwitterIcon',
                  'LinkedinIcon',
                ],
            }
        },
        created() {
            
        },
        methods: {
           
        },
        components: {
            NewsView
        },
        watch: {
            "$route.params.slug" : function( slug ) {
                
                if( this.editor ) {
                    this.editor.destroy();
                }
                
                this.$request.get("news.get", {
                    slug: this.$route.params.slug
                }).then( rsp => {
                    this.details = rsp.item;
                    this.similar = rsp.similar;
                    this.initContentViewer( JSON.parse(rsp.item.content) );
                })
            }
        },
        mounted() {
            this.$request.get("news.get", {
                slug: this.$route.params.slug
            }).then( rsp => {
                this.details = rsp.item;
                this.similar = rsp.similar;
            });
        }

    }

</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';

.codex-editor__redactor {
    padding-bottom: 16px !important;
}

.ce-block__content {
    max-width: 100% !important;
}

</style>
